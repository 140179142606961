<template>
  <div
    :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`"
  >
    <div class="card m-0 p-0">
      <div class="row m-0 p-0 w-100">

        <div class="col-12 col-md-12 col-lg-4 m-0 p-0">
          <LeftHeader
            tital="Work Flow"
            subtital="Template"
            line="1.2"
          />
        </div>

        <div class="col-12 col-md-12 col-lg-8 m-0 p-4">
    
        </div>

        <div class="col-12 mb-3">
          <WorkFlow/>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import LeftHeader from "@/components/Common/LeftHeader.vue";
import Button1 from "@/components/Common/Button1.vue";

import WorkFlow from '@/components/workflow/WorkFlow.vue';

export default {
  name: 'WorkFlowPage',
  components: {
    LeftHeader,
    Button1,
    WorkFlow,

  },
  data: function () {
    return {
        selectedMonth:null
      }
    },
  mounted() {
    this.$store.dispatch('display/SET_pageName',"WorkFlow Page")
  },
  methods: {
 
  }
}

</script>