<template>
    <div class="row">
        <div class="col-12">
          <div class="row ">
            <div class="col-12 ">
              <hr class="m-1 ">       
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="form-group row mb-25">
                  <div class="col-12 col-md-2  d-flex aling-items-center">
                      <label for="inputName" class=" col-form-label color-dark fs-14 fw-500 align-center">Group Name</label>
                  </div>
                  <div class="col-12 col-md-4 mb-2">
                      <input v-model="groupName" type="text" class="form-control ih-medium ip-gray radius-xs b-light px-15">
                  </div>
                  <div class="col-12 col-md-3 mb-2">
                      <div class="action-btn w-100">
                        <a @click="createGroup()"  class="btn w-100 text-white px-15 btn-success" >
                          <i  class="las la-plus fs-16"></i>Add Group</a>
                      </div>
                  </div>
                  <div class="col-12 col-md-3 mb-2">
                      <div class="action-btn w-100">
                        <a @click="clearGroup()"  class="btn w-100 text-white px-15 btn-danger" >
                          <i  class="las la-plus fs-16"></i>Clear Group</a>
                      </div>
                  </div>
              </div>
            </div>
            <div class="col-12">
              
            </div>
          </div>
          <div class="row">
              <div class="col-12 ">
                  <div class="d-flex group-box justify-content-center">
                      <div v-for="(e, index) in groupArrayProps"  :key="e.key" :style="`max-width:132px; border-radius: 8px; ${e.fill?`background-color: ${e.fill};`:''}`" class="m-2 p-3">
                          <span @click="deleteArray(index)" class="text-right float-right pointer m-0 text-white font-weight-bold "> x</span>
                          <p class="text-center text-dark font-weight-bold py-1 m-0" >{{e.text}}</p>
                          <img  :src="e.source"  width="100" alt="">
                          
                      </div>
                  </div>
              </div>
           
          </div>
        </div>
    </div>
</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import { useI18n } from 'vue-i18n';
export default {
  name: 'ModalCreateGroupFlow',
  props: [
    "templateName",
    "groupArrayProps",
  ],
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {

  },
  data: function () {
    return {
        groupName:''

    }
  },
  mounted() {
  },
  methods: {

    async createGroup(){
      try {
        if(!this.groupName){
          throw new Error("กรุณาใส่ Group Name")
        }
        let getNodeKey = this.groupArrayProps.map(e => e.key);
        let data = {
            templateName:this.templateName ,
            jsonGroupName:JSON.stringify([
                {
                  groupName: this.groupName, 
                  nodeKey: getNodeKey
                }
              ])

        }
        console.log(data)
        let getAPI = await serviceAPI.call_API('post','workflow/AddGroupTemplate',data,'auth');
        this.$emit('AddGroupSuccess')
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async clearGroup(){
      this.groupArrayProps.splice(0, this.groupArrayProps.length)
    },
    deleteArray:function(index) {
      // alert(index)
      this.groupArrayProps.splice(index, 1)
    }
  },
  computed: {

  },

}
</script>

<style scoped lang="scss">
.group-box{
  overflow: auto;
  border: 4px red dashed;
}
.input-groupName{
  border:none;

}
</style>
