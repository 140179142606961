<template>
  <div class="action-btn w-100">
    <Button1 tital="Add Template" @click="AddNewTemplate()" />
    <!-- <a href="#" class="btn w-100 px-15 btn-success" data-toggle="modal" data-target="#new-member">
      <i class="las la-plus fs-16"></i>Add Template</a> -->

    <!-- Modal -->
    <div class="modal fade new-member" id="new-member" role="dialog" tabindex="-1" aria-labelledby="staticBackdropLabel"
      style="display: none" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content radius-xl">
          <div class="modal-header justify-content-end">
  
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div class="modal-body pt-0 px-3">
            <div class="new-member-modal">
              <div v-if="isFirstBox" class="row">
                  <div class="col-12">
                    <div class="row bg-gray-400">
                        <div class="col-12 p-2">
                            <h3 class="modal-title fw-500 text-center text-white" id="staticBackdropLabel">Add Box</h3>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12 col-md-5  ">
                            
                            <div class="row">
                                <div class="col-12 box-icon p-3 " :style="`${nodeFirstData.fill?`background-color: ${nodeFirstData.fill};`:`background-color: #C4C4C4;`}`">
                                    <div v-if="isShowIcon" class="row">
                                        <div v-for="(e) in MainIconsData" :key="e.id" class="col-3 my-1 p-0">
                                            <img  class="mx-1 pointer" :src="`${e.synology_path}`" @click="nodeFirstData.source = `${e.synology_path}`;isShowIcon=false;" width="50"   alt="">
                                        </div>
                                    </div>
                                    <div  v-else class="row">
                                        <div class="col-12 ">
                                            <img v-if="nodeFirstData.source"  :src="nodeFirstData.source"  class="w-100" alt=""> 
                                            <img v-else class="w-100" src="/images/icon/addicon.png" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 p-0">
                                    <button @click="isShowIcon=true" class="w-100 btn-radius bg-white">Icon</button>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="row mb-2">
                                <div class="col-12 mb-4">
                                    <h1>FIRST BOX</h1>
                                </div>
                                <div class="col-12">
                                    <label for="text">Template Name</label>
                                    <input  v-model="createData.templateName" type="text" class="form-control bg-gray "  placeholder="Template Name">
                                </div>
                                <div class="col-12">
                                    <label for="text">Box Name</label>
                                    <input v-model="nodeFirstData.text" type="text" class="form-control bg-gray"  placeholder="Box Name">
                                </div>
                                <div class="col-12">
                                    <label for="Icon Color">Icon Color</label>
                                    <input v-model="nodeFirstData.fill" type="color" class="form-control" >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-12 col-md-4">
                            <label for="name47">Flag Approve</label>
                            <div class="custom-control custom-switch switch-primary switch-md ">
                                <input v-model="nodeFirstData.flagapprove" type="checkbox" id="switch-flagapprove-create-box1" checked="" class="custom-control-input" >
                                <label class="custom-control-label" for="switch-flagapprove-create-box1"></label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <label for="name47">Flag Upload</label>
                            <div class="custom-control custom-switch switch-primary switch-md ">
                                <input v-model="nodeFirstData.flagupload" type="checkbox" id="switch-flagupload-create-box1" checked="" class="custom-control-input" >
                                <label class="custom-control-label" for="switch-flagupload-create-box1"></label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <label for="name47">Flag Send Alert</label>
                            <div class="custom-control custom-switch switch-primary switch-md ">
                                <input v-model="nodeFirstData.flagsendalert" type="checkbox" id="switch-flagsendalert-create-box1" checked="" class="custom-control-input" >
                                <label class="custom-control-label" for="switch-flagsendalert-create-box1"></label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        
                        <div class="col-12 col-md-4">
                            <label for="datepicker">Department</label>
                            <select v-model="nodeFirstData.department" name="department" class="js-example-basic-single js-states form-control" >
                                <option disabled value="">Please select</option>
                                <option v-for="(e) in MainDepartmentsData" :key="e.id" :value="e.id"  >
                                    {{e.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-md-4">
                            <label for="text">Count down</label>
                            <input v-model="nodeFirstData.countdown" type="text" class="form-control"  placeholder="Count down">
                        </div>
                        <div class="col-12 col-md-4">
                            <label for="Unit">Unit</label>
                            <select v-model="nodeFirstData.countdownunit" name="Unit" class="js-example-basic-single js-states form-control" >
                                <option disabled value="">Please select</option>
                                <option  value="minute">minute</option>
                                <option  value="hour">hour</option>
                                <option  value="day">day</option>
                                <option  value="month">month</option>
                                <option  value="year">year</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-md-4">
                            <label for="text">Normal</label>
                            <input v-model="nodeFirstData.warningoptions.normal" @change="setMinMax($event)" type="number" min="0" max="100" class="form-control"  placeholder="Normal">
                        </div>
                        <div class="col-12 col-md-4">
                            <label for="text">Warning</label>
                            <input v-model="nodeFirstData.warningoptions.warning" @change="setMinMax($event)" type="number" min="0" max="100" class="form-control"  placeholder="Warning">
                        </div>
                        <div class="col-12 col-md-4">
                            <label for="text">Emergency</label>
                            <input v-model="nodeFirstData.warningoptions.emergency" @change="setMinMax($event)" type="number" min="0" max="100" class="form-control"  placeholder="Emergency">
                        </div>
                        
                    </div>
                    
                    <div class="row mb-2">
                        <div class="col-12 col-md-5">
                            <label for="Document">Document</label>
                            <select v-model="docBoxData.docId" name="department" class="js-example-basic-single js-states form-control" >
                                <option disabled value="">Please select</option>
                                <option v-for="(e) in MainDocData" :key="e.id" :value="e.id"  >
                                    {{e.doc_name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-md-3">
                            <label for="text">Count</label>
                            <input v-model="docBoxData.docCount" type="text" class="form-control"  placeholder="Count">
                        </div>
                        <div class="col-12 col-md-4">
                            <label for="text">Add</label>
                            <button @click="addDocBox(nodeFirstData)" class="btn btn-secondary" > Add Document</button>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table mb-0">
                                <thead>
                                    <tr class="userDatatable-header"> 
                                        <th>
                                            <p class="userDatatable-title text-center">Document Name</p>
                                        </th>
                                        <th>
                                            <p class="userDatatable-title text-center">Document Type</p>
                                        </th>
                                        <th>
                                            <p class="userDatatable-title text-center">Count</p>
                                        </th>
                                        <th>
                                            <p class="userDatatable-title text-center">Action</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(e,index) in nodeFirstData.actionLists" :key="index" >
                                        <td>
                                            <div class="userDatatable-content text-center">
                                                {{getDocName(e.docId)}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="userDatatable-content text-center">
                                                {{e.actionType}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="userDatatable-content text-center">
                                                {{e.docCount}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="userDatatable-content">
                                                <ul class="orderDatatable_actions mb-0 d-flex justify-content-center">
                                                    <li>
                                                        <a @click="DeleteDoc(nodeFirstData,e)" class="remove">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-12">
                  <div class="row bg-gray-400">
                      <div class="col-12 p-2">
                          <h3 class="modal-title fw-500 text-center text-white" id="staticBackdropLabel">Add Box</h3>
                      </div>
                  </div>
                  <div class="row p-3">
                      <div class="col-12 col-md-5  ">
                          
                          <div class="row">
                              <div class="col-12 box-icon p-3 " :style="`${nodeTwoData.fill?`background-color: ${nodeTwoData.fill};`:`background-color: #C4C4C4;`}`">
                                  <div v-if="isShowIcon" class="row">
                                      <div v-for="(e) in MainIconsData" :key="e.id" class="col-3 my-1 p-0">
                                          <img  class="mx-1 pointer" :src="`${e.synology_path}`" @click="nodeTwoData.source = `${e.synology_path}`;isShowIcon=false;" width="50"   alt="">
                                      </div>
                                  </div>
                                  <div  v-else class="row">
                                      <div class="col-12 ">
                                          <img v-if="nodeTwoData.source"  :src="nodeTwoData.source"  class="w-100" alt=""> 
                                          <img v-else class="w-100" src="/images/icon/addicon.png" alt="">
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="row mt-3">
                              <div class="col-12 p-0">
                                  <button @click="isShowIcon=true" class="w-100 btn-radius bg-white">Icon</button>
                              </div>
                          </div>
                          
                      </div>
                      <div class="col-12 col-md-7">
                          <div class="row mb-2">
                              <div class="col-12 mb-4">
                                  <h1>SECOND BOX </h1>
                              </div>
                              <div class="col-12">
                                  <label for="text">Template Name</label>
                                  <input  v-model="createData.templateName" type="text" class="form-control bg-gray "  placeholder="Template Name">
                              </div>
                              <div class="col-12">
                                  <label for="text">Box Name</label>
                                  <input v-model="nodeTwoData.text" type="text" class="form-control bg-gray"  placeholder="Box Name">
                              </div>
                              <div class="col-12">
                                  <label for="Icon Color">Icon Color</label>
                                  <input v-model="nodeTwoData.fill" type="color" class="form-control" >
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="row mb-2">
                      <div class="col-12 col-md-4">
                          <label for="name47">Flag Approve</label>
                          <div class="custom-control custom-switch switch-primary switch-md ">
                              <input v-model="nodeTwoData.flagapprove" type="checkbox" id="switch-flagapprove-create-box2" checked="" class="custom-control-input" >
                              <label class="custom-control-label" for="switch-flagapprove-create-box2"></label>
                          </div>
                      </div>
                      <div class="col-12 col-md-4">
                          <label for="name47">Flag Upload</label>
                          <div class="custom-control custom-switch switch-primary switch-md ">
                              <input v-model="nodeTwoData.flagupload" type="checkbox" id="switch-flagupload-create-box2" checked="" class="custom-control-input" >
                              <label class="custom-control-label" for="switch-flagupload-create-box2"></label>
                          </div>
                      </div>
                      <div class="col-12 col-md-4">
                          <label for="name47">Flag Send Alert</label>
                          <div class="custom-control custom-switch switch-primary switch-md ">
                              <input v-model="nodeTwoData.flagsendalert" type="checkbox" id="switch-flagsendalert-create-box2" checked="" class="custom-control-input" >
                              <label class="custom-control-label" for="switch-flagsendalert-create-box2"></label>
                          </div>
                      </div>
                  </div>
                  <div class="row mb-2">
                      
                      <div class="col-12 col-md-4">
                          <label for="datepicker">Department</label>
                          <select v-model="nodeTwoData.department" name="department" class="js-example-basic-single js-states form-control" >
                              <option disabled value="">Please select</option>
                              <option v-for="(e) in MainDepartmentsData" :key="e.id" :value="e.id"  >
                                  {{e.name}}
                              </option>
                          </select>
                      </div>
                      <div class="col-12 col-md-4">
                          <label for="text">Count down</label>
                          <input v-model="nodeTwoData.countdown" type="text" class="form-control"  placeholder="Count down">
                      </div>
                      <div class="col-12 col-md-4">
                          <label for="Unit">Unit</label>
                          <select v-model="nodeTwoData.countdownunit" name="Unit" class="js-example-basic-single js-states form-control" >
                              <option disabled value="">Please select</option>
                              <option  value="minute">minute</option>
                              <option  value="hour">hour</option>
                              <option  value="day">day</option>
                              <option  value="month">month</option>
                              <option  value="year">year</option>
                          </select>
                      </div>
                  </div>
                  <div class="row mb-2">
                      <div class="col-12 col-md-4">
                          <label for="text">Normal</label>
                          <input v-model="nodeTwoData.warningoptions.normal" @change="setMinMax($event)" type="number" min="0" max="100" class="form-control"  placeholder="Normal">
                      </div>
                      <div class="col-12 col-md-4">
                          <label for="text">Warning</label>
                          <input v-model="nodeTwoData.warningoptions.warning" @change="setMinMax($event)" type="number" min="0" max="100" class="form-control"  placeholder="Warning">
                      </div>
                      <div class="col-12 col-md-4">
                          <label for="text">Emergency</label>
                          <input v-model="nodeTwoData.warningoptions.emergency" @change="setMinMax($event)" type="number" min="0" max="100" class="form-control"  placeholder="Emergency">
                      </div>
                      
                  </div>
                  
                  <div class="row mb-2">
                      <div class="col-12 col-md-5">
                          <label for="Document">Document</label>
                          <select v-model="docBoxData.docId" name="department" class="js-example-basic-single js-states form-control" >
                              <option disabled value="">Please select</option>
                              <option v-for="(e) in MainDocData" :key="e.id" :value="e.id"  >
                                  {{e.doc_name}}
                              </option>
                          </select>
                      </div>
                      <div class="col-12 col-md-3">
                          <label for="text">Count</label>
                          <input v-model="docBoxData.docCount" type="text" class="form-control"  placeholder="Count">
                      </div>
                      <div class="col-12 col-md-4">
                          <label for="text">Add</label>
                          <button @click="addDocBox(nodeTwoData)" class="btn btn-secondary" > Add Document</button>
                      </div>
                      
                  </div>
                  <div class="row">
                      <div class="col-12">
                          <table class="table mb-0">
                              <thead>
                                  <tr class="userDatatable-header"> 
                                      <th>
                                          <p class="userDatatable-title text-center">Document Name</p>
                                      </th>
                                      <th>
                                          <p class="userDatatable-title text-center">Document Type</p>
                                      </th>
                                      <th>
                                          <p class="userDatatable-title text-center">Count</p>
                                      </th>
                                      <th>
                                          <p class="userDatatable-title text-center">Action</p>
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(e,index) in nodeTwoData.actionLists" :key="index" >
                                      <td>
                                          <div class="userDatatable-content text-center">
                                              {{getDocName(e.docId)}}
                                          </div>
                                      </td>
                                      <td>
                                          <div class="userDatatable-content text-center">
                                              {{e.actionType}}
                                          </div>
                                      </td>
                                      <td>
                                          <div class="userDatatable-content text-center">
                                              {{e.docCount}}
                                          </div>
                                      </td>
                                      <td>
                                          <div class="userDatatable-content">
                                              <ul class="orderDatatable_actions mb-0 d-flex justify-content-center">
                                                  <li>
                                                      <a @click="DeleteDoc(nodeTwoData,e)" class="remove">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                      </a>
                                                  </li>
                                              </ul>
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                </div>
              </div>














              <!-- <div class="row">
                <div class="col-12">
                  <div class="d-flex justify-content-center">
                    <div v-if="nodeFirstData.source" :style="`max-width:132px; border-radius: 8px; ${ nodeFirstData.fill ? `background-color: ${nodeFirstData.fill};` : '' }`" class="p-3">
                      <p class="text-center text-dark font-weight-bold py-1">
                        {{ nodeFirstData.text }}
                      </p>
                      <img :src="nodeFirstData.source" width="100" alt="" />
                    </div>
                    <div v-if="nodeTwoData.source" class="d-flex justify-content-center align-items-center">
                      <div class="line-between"></div>
                    </div>
                    <div v-if="nodeTwoData.source" :style="`max-width:132px; border-radius: 8px; ${
                        nodeTwoData.fill ? `background-color: ${nodeTwoData.fill};` : '' }`" class="p-3">
                      <p class="text-center text-dark font-weight-bold py-1">
                        {{ nodeTwoData.text }}
                      </p>
                      <img :src="nodeTwoData.source" width="100" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12">
                  <label for="text">Template Name</label>
                  <input v-model="createData.templateName" type="text" class="form-control"
                    placeholder="Template Name" />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h3 class="text-center">Box First</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-12 col-md-6">
                  <label for="text">Box Name</label>
                  <input v-model="nodeFirstData.text" type="text" class="form-control" placeholder="Box Name" />
                </div>
                <div class="col-12 col-md-6">
                  <label for="Department">Department</label>
                  <select v-model="nodeFirstData.department" name="department"
                    class="js-example-basic-single js-states form-control">
                    <option disabled value="">Please select</option>
                    <option v-for="e in MainDepartmentsData" :key="e.id" :value="e.id">
                      {{ e.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-md-6 overflow-auto">
                  <label for="text">Select Icon</label>
                  <div class="d-flex flex-nowrap">
                    <div class="p-1">
                      <img v-for="e in MainIconsData" class="mx-1 pointer" :src="`${e.synology_path}`"
                        @click="nodeFirstData.source = `${e.synology_path}`" width="50" :key="e.id" alt="" />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <label for="Icon Color">Icon Color</label>
                  <input v-model="nodeFirstData.fill" type="color" class="form-control" />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <label for="text">Count down</label>
                  <input v-model="nodeFirstData.countdown" type="text" class="form-control" placeholder="Count down" />
                </div>
                <div class="col-12 col-md-6">
                  <label for="Unit">Unit</label>
                  <select v-model="nodeFirstData.countdownunit" name="Unit"
                    class="js-example-basic-single js-states form-control">
                    <option disabled value="">Please select</option>
                    <option value="minute">minute</option>
                    <option value="hour">hour</option>
                    <option value="day">day</option>
                    <option value="month">month</option>
                    <option value="year">year</option>
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-md-4">
                  <label for="text">Normal</label>
                  <input v-model="nodeFirstData.warningoptions.normal" @change="setMinMax($event)" type="number" min="0"
                    max="100" class="form-control" placeholder="Normal" />
                </div>
                <div class="col-12 col-md-4">
                  <label for="text">Warning</label>
                  <input v-model="nodeFirstData.warningoptions.warning" @change="setMinMax($event)" type="number"
                    min="0" max="100" class="form-control" placeholder="Warning" />
                </div>
                <div class="col-12 col-md-4">
                  <label for="text">Emergency</label>
                  <input v-model="nodeFirstData.warningoptions.emergency" @change="setMinMax($event)" type="number"
                    min="0" max="100" class="form-control" placeholder="Emergency" />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-md-4">
                  <label for="name47">Flag Approve</label>
                  <div class="
                      custom-control custom-switch
                      switch-primary switch-md
                    ">
                    <input v-model="nodeFirstData.flagapprove" type="checkbox" id="switch-flagapprove" checked=""
                      class="custom-control-input" />
                    <label class="custom-control-label" for="switch-flagapprove"></label>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <label for="name47">Flag Upload</label>
                  <div class="
                      custom-control custom-switch
                      switch-primary switch-md
                    ">
                    <input v-model="nodeFirstData.flagupload" type="checkbox" id="switch-flagupload" checked=""
                      class="custom-control-input" />
                    <label class="custom-control-label" for="switch-flagupload"></label>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <label for="name47">Flag Send Alert</label>
                  <div class="
                      custom-control custom-switch
                      switch-primary switch-md
                    ">
                    <input v-model="nodeFirstData.flagsendalert" type="checkbox" id="switch-flagsendalert" checked=""
                      class="custom-control-input" />
                    <label class="custom-control-label" for="switch-flagsendalert"></label>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-12 col-md-5">
                  <label for="Document">Document</label>
                  <select v-model="docBoxData.docId" name="department"
                    class="js-example-basic-single js-states form-control">
                    <option disabled value="">Please select</option>
                    <option v-for="e in MainDocData" :key="e.id" :value="e.id">
                      {{ e.doc_name }}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-3">
                  <label for="text">Count</label>
                  <input v-model="docBoxData.docCount" type="text" class="form-control" placeholder="Count" />
                </div>
                <div class="col-12 col-md-4">
                  <label for="text">Add</label>
                  <button @click="addDocBox(nodeFirstData)" class="btn btn-secondary">
                    Add Document
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table class="table mb-0">
                    <thead>
                      <tr class="userDatatable-header">
                        <th>
                          <p class="userDatatable-title text-center">
                            Document Name
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center">
                            Document Type
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center">Count</p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center">Action</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(e, index) in nodeFirstData.actionLists" :key="index">
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ getDocName(e.docId) }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.actionType }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.docCount }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content">
                            <ul class="
                                orderDatatable_actions
                                mb-0
                                d-flex
                                justify-content-center
                              ">
                              <li>
                                <a @click="DeleteDoc(nodeFirstData, e)" class="remove">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-trash-2">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                    </path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                  </svg>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h3 class="text-center">Box Second</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-12 col-md-6">
                  <label for="Box Name">Box Name</label>
                  <input v-model="nodeTwoData.text" type="text" class="form-control" placeholder="Box Name" />
                </div>
                <div class="col-12 col-md-6">
                  <label for="Department">Department</label>
                  <select v-model="nodeTwoData.department" name="Department"
                    class="js-example-basic-single js-states form-control">
                    <option disabled value="">Please select</option>
                    <option v-for="e in MainDepartmentsData" :key="e.id" :value="e.id">
                      {{ e.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-md-6 overflow-auto">
                  <label for="text">Select Icon</label>
                  <div class="d-flex flex-nowrap">
                    <div class="p-1">
                      <img v-for="e in MainIconsData" class="mx-1 pointer" :src="`${e.synology_path}`"
                        @click="nodeTwoData.source = `${e.synology_path}`" width="50" :key="e.id" alt="" />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <label for="Icon Color">Icon Color</label>
                  <input v-model="nodeTwoData.fill" type="color" class="form-control" />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-md-6">
                  <label for="text">Count down</label>
                  <input v-model="nodeTwoData.countdown" type="text" class="form-control" placeholder="Count down" />
                </div>
                <div class="col-12 col-md-6">
                  <label for="Unit">Unit</label>
                  <select v-model="nodeTwoData.countdownunit" name="Unit"
                    class="js-example-basic-single js-states form-control">
                    <option disabled value="">Please select</option>
                    <option value="minute">minute</option>
                    <option value="hour">hour</option>
                    <option value="day">day</option>
                    <option value="month">month</option>
                    <option value="year">year</option>
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-md-4">
                  <label for="text">Normal</label>
                  <input v-model="nodeTwoData.warningoptions.normal" @change="setMinMax($event)" type="number" min="0"
                    max="100" class="form-control" placeholder="Normal" />
                </div>
                <div class="col-12 col-md-4">
                  <label for="text">Warning</label>
                  <input v-model="nodeTwoData.warningoptions.warning" @change="setMinMax($event)" type="number" min="0"
                    max="100" class="form-control" placeholder="Warning" />
                </div>
                <div class="col-12 col-md-4">
                  <label for="text">Emergency</label>
                  <input v-model="nodeTwoData.warningoptions.emergency" @change="setMinMax($event)" type="number"
                    min="0" max="100" class="form-control" placeholder="Emergency" />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-md-4">
                  <label for="name47">Flag Approve</label>
                  <div class="
                      custom-control custom-switch
                      switch-primary switch-md
                    ">
                    <input v-model="nodeTwoData.flagapprove" type="checkbox" id="switch-flagapprove-two" checked=""
                      class="custom-control-input" />
                    <label class="custom-control-label" for="switch-flagapprove-two"></label>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <label for="name47">Flag Upload</label>
                  <div class="
                      custom-control custom-switch
                      switch-primary switch-md
                    ">
                    <input v-model="nodeTwoData.flagupload" type="checkbox" id="switch-flagupload-two" checked=""
                      class="custom-control-input" />
                    <label class="custom-control-label" for="switch-flagupload-two"></label>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <label for="name47">Flag Send Alert</label>
                  <div class="
                      custom-control custom-switch
                      switch-primary switch-md
                    ">
                    <input v-model="nodeTwoData.flagsendalert" type="checkbox" id="switch-flagsendalert-two" checked=""
                      class="custom-control-input" />
                    <label class="custom-control-label" for="switch-flagsendalert-two"></label>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-md-5">
                  <label for="Document">Document</label>
                  <select v-model="docBoxData.docId" name="department"
                    class="js-example-basic-single js-states form-control">
                    <option disabled value="">Please select</option>
                    <option v-for="e in MainDocData" :key="e.id" :value="e.id">
                      {{ e.doc_name }}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-3">
                  <label for="text">Count</label>
                  <input v-model="docBoxData.docCount" type="text" class="form-control" placeholder="Count" />
                </div>
                <div class="col-12 col-md-4">
                  <label for="text">Add</label>
                  <button @click="addDocBox(nodeTwoData)" class="btn btn-secondary">
                    Add Document
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table class="table mb-0">
                    <thead>
                      <tr class="userDatatable-header">
                        <th>
                          <p class="userDatatable-title text-center">
                            Document Name
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center">
                            Document Type
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center">Count</p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center">Action</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(e, index) in nodeTwoData.actionLists" :key="index">
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ getDocName(e.docId) }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.actionType }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.docCount }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content">
                            <ul class="
                                orderDatatable_actions
                                mb-0
                                d-flex
                                justify-content-center
                              ">
                              <li>
                                <a @click="DeleteDoc(nodeTwoData, e)" class="remove">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-trash-2">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                    </path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                  </svg>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->
              <div class="button-group d-flex justify-content-center pt-25">
                
                <button v-if="isFirstBox" @click="nextFlow()" class=" btn-radius-48 btn-bg-gray text-white px-5 py-3">
                  Next
                </button>
                <button v-else @click="isFirstBox=true" class=" btn-radius-48 btn-bg-gray text-white px-5 py-3">
                  Back
                </button>
                <button v-if="!isFirstBox" @click="AddJobStageTemplates($event)" class=" btn-radius-48 btn-bg-gray text-white px-5 py-3">
                  Add New Template
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";
  import Button1 from "@/components/Common/Button1.vue";

  export default {
    name: "ModalCreateTemplate",
    components: {
      Button1
    },
    data: function () {
      return {
        createData: {
          templateName: "",
          jsnNodeDataArray: [],
          jsnLinkDataArray: [],
        },

        nodeFirstData: {
          key: 1,
          fill: "#C4C4C4",
          source: "",
          text: "",
          department: "",
          flagapprove: true,
          flagupload: false,
          flagsendalert: false,
          warningoptions: {
            normal: 0,
            warning: 0,
            emergency: 0,
          },
          actionLists: [],
          countdown: '0',
          countdownunit: 'day'
        },
        nodeTwoData: {
          key: 2,
          fill: "#C4C4C4",
          source: "",
          text: "",
          department: "",
          flagapprove: true,
          flagupload: false,
          flagsendalert: false,
          warningoptions: {
            normal: 0,
            warning: 0,
            emergency: 0,
          },
          actionLists: [],
          countdown: '0',
          countdownunit: 'day'
        },
        docBoxData: {
          docId: "",
          actionType: "document",
          docCount: "",
        },
        MainDocData: [],
        MainColorsData: [],
        MainIconsData: [],
        MainDepartmentsData: [],
        isShowIcon:false,
        isFirstBox:true
      };
    },
    mounted() {
      //   this.startSelect2()
      this.GetMainDoc();
      // this.GetMainColors()
      this.GetMainIcons();
      this.GetMainDepartments();
    },
    methods: {
      async AddJobStageTemplates() {
        try {
          if(this.nodeTwoData.department==''){
              throw new Error("กรุณาเลือก Department กล่องสอง ก่อนครับ")
          }
          this.createData.jsnNodeDataArray = [];
          this.createData.jsnNodeDataArray.push(this.nodeFirstData);
          this.createData.jsnNodeDataArray.push(this.nodeTwoData);
          let data = {
            templateName: this.createData.templateName,
            jsnNodeDataArray: JSON.stringify(this.createData.jsnNodeDataArray),
            jsnLinkDataArray: JSON.stringify([{ from: 1, to: 2 }]),
          };
          console.log(data);
          let getAPI = await serviceAPI.call_API( "post", "workflow/AddJobStageTemplates", data, "auth" );
          // console.log("AddJobStageTemplates:")
          // console.log(getAPI);
          $("#new-member").modal("hide");
          this.$emit("addTemplate");
          this.refreshForm();
        } catch (error) {
          $("#new-member").modal("hide");
          serviceMain.showErrorAlert(this, error);
        }
      },
      async nextFlow(){
        try {
          if(this.nodeFirstData.department==''){
              throw new Error("กรุณาเลือก Department กล่องแรก ก่อนครับ")
          }
          this.isFirstBox=false
        } catch (error) {
          $("#new-member").modal("hide");
          serviceMain.showErrorAlert(this, error);
        }
        
      },
      async refreshForm() {
        try {
          (this.createData = {
            templateName: "",
            jsnNodeDataArray: [],
            jsnLinkDataArray: [],
          }),
          (this.nodeFirstData = {
            key: 1,
            fill: "",
            source: "",
            text: "",
            department: "",
            flagapprove: true,
            flagupload: false,
            flagsendalert: false,
            warningoptions: {
              normal: 0,
              warning: 0,
              emergency: 0,
            },
            actionLists: [],
            countdown: '0',
            countdownunit: 'day'
          }),
          (this.nodeTwoData = {
            key: 2,
            fill: "",
            source: "",
            text: "",
            department: "",
            flagapprove: true,
            flagupload: false,
            flagsendalert: false,
            warningoptions: {
              normal: 0,
              warning: 0,
              emergency: 0,
            },
            actionLists: [],
            countdown: '0',
            countdownunit: 'day'
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error);
        }
      },
      // async startSelect2(){
      //     $("#select2Box1CreateTemplate").select2({
      //         placeholder: "Tags Mode",
      //         dropdownCssClass: "tag",
      //         // tags: ["red", "green", "blue"],
      //         allowClear: true,
      //     });
      //     // $("#plantOption").select2({
      //     //     placeholder: "เลือก Plant",
      //     //     dropdownCssClass: "tag",
      //     //     allowClear: true,
      //     // }).on('select2:select', (e) => {
      //     //     this.plant_ID = $("#plantOption").val().join(",");
      //     // });

      // },
      async GetMainDoc() {
        try {
          let data = {};
          let getAPI = await serviceAPI.call_API(
            "get",
            "workflow/DocumentTemplates",
            data,
            "auth"
          );
          console.log("GetMainDoc:");
          console.log(getAPI);
          this.MainDocData = getAPI.data.data;
        } catch (error) {
          serviceMain.showErrorAlert(this, error);
        }
      },
      async GetMainColors() {
        try {
          let data = {};
          let getAPI = await serviceAPI.call_API(
            "get",
            "workflow/GetMainColors",
            data,
            "auth"
          );
          console.log("GetMainColors:");
          console.log(getAPI);
          this.MainColorsData = getAPI.data.data;
          // data: Array(3)
          // 0: {id: 1, color: '#FF0000'}
          // 1: {id: 2, color: '#00FF00'}
          // 2: {id: 3, color: '#0000FF'}
        } catch (error) {
          serviceMain.showErrorAlert(this, error);
        }
      },
      async GetMainIcons() {
        try {
          let data = {};
          let getAPI = await serviceAPI.call_API(
            "get",
            "workflow/GetMainIcons",
            data,
            "auth"
          );
          console.log("GetMainIcons:");
          console.log(getAPI);
          this.MainIconsData = getAPI.data.data;
          // data: Array(2)
          // 0:
          // icon_name: "hand"
          // icon_path: "/images/icon/hand.png"
          // id: 1
          // [[Prototype]]: Object
          // 1: {id: 2, icon_path: '/images/icon/alert.png', icon_name: 'alert'}
        } catch (error) {
          serviceMain.showErrorAlert(this, error);
        }
      },
      async GetMainDepartments() {
        try {
          let data = {};
          let getAPI = await serviceAPI.call_API(
            "get",
            "workflow/GetMainDepartments",
            data,
            "auth"
          );
          this.MainDepartmentsData = getAPI.data.data;
        } catch (error) {
          serviceMain.showErrorAlert(this, error);
        }
      },
      async setMinMax(e) {
        let getInt = parseInt(e.target.value);
        if (getInt > 100) {
          e.target.value = 100;
        }
        if (getInt < 0) {
          e.target.value = 0;
        }
      },
      async addDocBox(e) {
        e.actionLists.push({
          docId: this.docBoxData.docId,
          actionType: this.docBoxData.actionType,
          docCount: this.docBoxData.docCount,
        });
      },
      getDocName(val) {
        let getObj = this.MainDocData.find((e) => e.id == val);
        return getObj.doc_name;
      },
      DeleteDoc(val_Obj, element) {
        val_Obj.actionLists = val_Obj.actionLists.filter(
          (e) => e.docId != element.docId
        );
      },
      AddNewTemplate(){
        try {
          if (!this.canCreateTemplate) {
            throw new Error('ไม่สามารถเข้าถึงสิทธิ์การสร้าง Template ได้')
          }
          $('#new-member').modal('show')
          this.isFirstBox=true;
        } catch (error) {
          serviceMain.showErrorAlert(this, error);
        }
      }
    },
    computed: {
      user: function () {
        return this.$store.getters["auth/getUser"];
      },
      canCreateTemplate: function () {
        return this.user.is_admin || this.user.permissions.template_create;
      },
  },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .line-between {
    width: 50px;
    border-bottom: 5px black solid;
  }

  hr {
    margin: 15px 0px !important;
  }

  .box-icon {
    max-height: 500px;
    overflow: auto;
  }

  label {
    color: black !important;
    font-weight: 700;
  }
</style>