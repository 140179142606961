<template>

<div class="row ">

    <!-- <div class="col-12">
      <div class="d-flex justify-content-start align-items-center box-draw-work-flow">
        <div v-for="e1 in ListDataProps" :key="e1.order" class="col-flow">
          <div v-for="e2 in e1.boxList" :key="e2.boxList" class="work-flow-card">
            <div class="work-flow-box supply">

            </div>
            <div class="d-flex justify-content-start ">
              <div v-for="e3 in e2.documentList" :key="e3.boxList" class="work-flow-doc">

              </div>
            </div>
          </div>
 
        </div>

      </div>
    </div> -->
    <div class="col-12">
      <div id="sample">
  <div style="width: 100%; display: flex; justify-content: space-between position-relative ">
    <div class="hide-banner"></div>
    <div id="myDiagramDiv" style="flex-grow: 1; height: 480px; background-color:#C4C4C4; "></div>
    
  </div>

</div>
    </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import { Bezier } from "bezier-js";
import { $ as jq1 }from "jquery";
export default {
  name: 'WorkFlowList',
  props: [
    "ListDataProps"
  ],
   data: function () {
    return {
      myDiagramData:null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async startLine(){
      
      // setTimeout(function(){ $('.supply').bezier({strokeColor : 'red',}); }, 3000);
      
    },
    async init() {
      console.log(this.ListDataProps)
      let _this = this
      let myDiagram = null
      var $ = go.GraphObject.make;  // for conciseness in defining templates

      // var yellowgrad = $(go.Brush, "Linear", { 0: "rgb(254, 201, 0)", 1: "rgb(254, 162, 0)" });

      var bigfont = "bold 13pt Helvetica, Arial, sans-serif";
      var smallfont = "bold 11pt Helvetica, Arial, sans-serif";

      // Common text styling
      function textStyle() {
        return {
          margin: 6,
          wrap: go.TextBlock.WrapFit,
          textAlign: "center",
          editable: true,
          font: bigfont
        }
      }

      myDiagram = $(go.Diagram, "myDiagramDiv",
          {
            // have mouse wheel events zoom in and out instead of scroll up and down
            "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
            initialAutoScale: go.Diagram.Uniform,
            "linkingTool.direction": go.LinkingTool.ForwardsOnly,
            layout: $(go.LayeredDigraphLayout, { isInitial: false, isOngoing: false, layerSpacing: 100 }),
            // layout: $(go.TreeLayout,{ angle: 0, layerSpacing: 80 }),
            "undoManager.isEnabled": true
          });

      // when the document is modified, add a "*" to the title and enable the "Save" button
      myDiagram.addDiagramListener("Modified", function(e) {
        // let getJson = myDiagram.model.toJson()
        // _this.save(getJson)
        // alert("Modify")
        // var button = document.getElementById("SaveButton");
        // if (button) button.disabled = !myDiagram.isModified;
        // var idx = document.title.indexOf("*");
        // if (myDiagram.isModified) {
        //   if (idx < 0) document.title += "*";
        // } else {
        //   if (idx >= 0) document.title = document.title.substr(0, idx);
        // }
      });

      var defaultAdornment =
        $(go.Adornment, "Spot",
          $(go.Panel, "Auto",
            $(go.Shape, { fill: null, stroke: "dodgerblue", strokeWidth: 4 }),
            $(go.Placeholder)),
          // the button to create a "next" node, at the top-right corner
          $("Button",
            {
              "ButtonBorder.stroke": "white",
              "_buttonFillOver": "white",
              alignment: go.Spot.Right,
              click: addData
            },  // this function is defined below
            new go.Binding("visible", "", function(a) { return !a.diagram.isReadOnly; }).ofObject(),
            $(go.Shape, "PlusLine", { desiredSize: new go.Size(15, 15) , stroke: "#868686", strokeWidth: 1 })
          ),
          $("Button",
            {
              "ButtonBorder.stroke": "red",
              "_buttonFillOver": "red",
              alignment: go.Spot.TopLeft,
              click: delData
            },  // this function is defined below
            new go.Binding("visible", "", function(a) { return !a.diagram.isReadOnly; }).ofObject(),
            $(go.Shape, "LineH", { desiredSize: new go.Size(15, 15) , stroke: "#868686", strokeWidth: 1 })
          ),
          $("Button",
            {
              "ButtonBorder.stroke": "white",
              "_buttonFillOver": "white",
              alignment: go.Spot.Center,
              click: editData
            },  // this function is defined below
            new go.Binding("visible", "", function(a) { return !a.diagram.isReadOnly; }).ofObject(),
            $(go.TextBlock, "Edit", {  font: "bold 20pt serif",textAlign: "center" ,verticalAlignment: go.Spot.Center,desiredSize: new go.Size(70, 70) })
          ),

          $("Button",
            {
              "ButtonBorder.stroke": "white",
              "_buttonFillOver": "white",
              alignment: go.Spot.Top,
              click: groupData
            },  // this function is defined below
            new go.Binding("visible", "", function(a) { return !a.diagram.isReadOnly; }).ofObject(),
            $(go.TextBlock, "Group", {  font: "bold 12pt serif",textAlign: "center" ,verticalAlignment: go.Spot.Center })
          ),
          
        );

      // define the Node template
      myDiagram.nodeTemplate =
        $(go.Node, "Auto",
          
          { selectionAdornmentTemplate: defaultAdornment ,},
          new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
          
          // define the node's outer shape, which will surround the TextBlock
          
          $(go.Shape, "RoundedRectangle",
            {
              
              // fill: 'rgba(76, 168, 128, 0.78)', 
              stroke: "black",
              portId: "", 
              fromLinkable: true, 
              toLinkable: true, 
              cursor: "pointer",
              toEndSegmentLength: 50, 
              fromEndSegmentLength: 40
            },
            new go.Binding("fill").makeTwoWay()
            ),
            
          //   $(go.Part, "Horizontal",
          //   $(go.Shape, "Rectangle",        { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "RoundedRectangle", { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "Ellipse",          { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "Diamond",          { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "TriangleRight",    { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "TriangleDown",     { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "TriangleLeft",     { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "TriangleUp",       { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "MinusLine",        { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "PlusLine",         { width: 40, height: 60, margin: 4, fill: null }),
          //   $(go.Shape, "XLine",            { width: 40, height: 60, margin: 4, fill: null })
          // ),
          //  $(go.TextBlock, "hello", { row: 1, column: 1 }),
            $(go.Picture,
            //  "/images/icon/hand.png",
              { 
                column: 2,
                width: 100, 
                height: 100, 
                margin: 0, 
                imageStretch: go.GraphObject.Uniform 
              },
              new go.Binding("source").makeTwoWay()
               ),
              $(go.TextBlock, 
              { 
                // text: "Engineer",
                font: bigfont, 
                verticalAlignment: go.Spot.Top,
                width: 140, 
                height: 170, 
                margin: 10,
                textAlign: "center" 
              },
              
              new go.Binding("text").makeTwoWay()
                ),
                $(go.Panel, "Spot",
                { margin: 1 ,alignment: new go.Spot(0, 1, 0, 0)  },
                $(go.Panel, "Horizontal",
                { margin: 4 },
                new go.Binding("itemArray", "actionBars"),
                {
                  itemTemplate:
                    $(go.Panel, "Auto",
                      { margin: 2, },
                      $(go.Shape, "RoundedRectangle",
                        {  },
                        new go.Binding("fill").makeTwoWay()
                        ),
                      $(go.Picture,

                        { 
                          column: 2,
                          width: 20, 
                          height: 20, 
                          margin: 0, 
                          imageStretch: go.GraphObject.Uniform 
                        },
                        new go.Binding("source").makeTwoWay()
                        ),
                    )  // end of itemTemplate
                })),
                
           
                // $(go.Panel, "Vertical",  // or "Position"
                //   { background: "lightgray" },
                //   $(go.TextBlock, "default, at (0,0)", { background: "lightgreen" }),
                //   $(go.TextBlock, "(100, 0)", { position: new go.Point(100, 0), background: "lightgreen" }),
                //   $(go.TextBlock, "(0, 100)", { position: new go.Point(0, 100), background: "lightgreen" }),
                //   $(go.TextBlock, "(55, 28)", { position: new go.Point(55, 28), background: "lightgreen" }),
                //   $(go.TextBlock, "(33, 70)", { position: new go.Point(33, 70), background: "lightgreen" }),
                //   $(go.TextBlock, "(100, 100)", { position: new go.Point(100, 100), background: "lightgreen" })
                // ),
                
              
                // $("Button",
                // { alignment: go.Spot.BottomRight, alignmentFocus: go.Spot.BottomRight,
                //   click: function (e, obj) { changeZOrder(1, obj); } },
                // $(go.Shape, "PlusLine", { width: 14, height: 14 }))      
            );
      myDiagram.groupTemplate =
      $(go.Group, "Auto",
        
        { layout: $(go.LayeredDigraphLayout,{ direction: 0, columnSpacing: 10 }) },
        
        $(go.Shape, "RoundedRectangle", // surrounds everything
          { 
            fill: "rgba(255,255,255,0)" , 
            stroke: "red", 
            strokeWidth: 4,
            strokeDashArray: [30, 12]
          }
        ),
       
        $(go.Panel, "Vertical",  // position header above the subgraph
          { 
            defaultAlignment: go.Spot.Center ,
            padding: new go.Margin(15, 15),
          },
          $(go.TextBlock,         // group title
            { alignment: go.Spot.Top, font: "Bold 25pt Sans-Serif" },
            new go.Binding("text", "key")
          ),
          $(go.Panel, "Horizontal",  // the header
            { 
              defaultAlignment: go.Spot.TopLeft 
            },
            $("SubGraphExpanderButton"),  // this Panel acts as a Button
     
          ),
          $(go.Placeholder,     // represents area for all member parts
            { 
              padding: new go.Margin(30, 30), 
              // background: "transparent" 
            }),
          
        ),
         
        // {
        //   background: "transparent",
        //   ungroupable: true,
        //   computesBoundsAfterDrag: true,
        //   handlesDragDropForMembers: true,
        // },
        // $(go.Shape, "Rectangle",
        //   { 
        //     fill: "rgba(255,255,255,0)" , 
        //     stroke: "red", 
        //     strokeWidth: 4,
        //     strokeDashArray: [30, 12]
        //   },
        
  
        // ),
        // $(go.Panel, "Vertical",  // title above Placeholder
        //     $(go.Panel, "Horizontal",  // button next to TextBlock
        //       { stretch: go.GraphObject.Horizontal,  },
        //       new go.Binding("background", "fill"),
        //       $("SubGraphExpanderButton",
        //         { alignment: go.Spot.Right, margin: 5 }),
        //       $(go.TextBlock,
        //         {
        //           alignment: go.Spot.Left,
        //           editable: true,
        //           margin: 5,
        //           opacity: 0.75,  // allow some color to show through
        //           stroke: "#404040"
        //         },
        //         new go.Binding("text", "text").makeTwoWay())
        //     ),  // end Horizontal Panel
        //     $(go.Placeholder,
        //       { padding: 5, alignment: go.Spot.TopLeft })
        //   ) 
        
      );    
      // myDiagram.groupTemplate =
      // $(go.Group, "Vertical",
      //   $(go.TextBlock,         // group title
      //     { alignment: go.Spot.Top, font: "Bold 25pt Sans-Serif" },
      //     new go.Binding("text", "key")),
      //   $(go.Panel, "Auto",
      //     $(go.Shape, "RoundedRectangle",  // surrounds the Placeholder
      //       { parameter1: 14,
      //         fill: "rgba(255,255,255,0)" , 
      //         stroke: "red", 
      //         strokeWidth: 4,
      //         strokeDashArray: [30, 12]
      //       },
            
      //     ),
          
      //     // $(go.Panel, "Vertical",  // the header
      //     //   { defaultAlignment: go.Spot.Top },
      //     //   $("SubGraphExpanderButton"),  // this Panel acts as a Button
      //     //   $(go.TextBlock,     // group title near top, next to button
      //     //     { font: "Bold 12pt Sans-Serif" },
      //     //     new go.Binding("text", "key"))
      //     // ),
      //     $(go.Placeholder,    // represents the area of all member parts,
      //       { padding: 20 ,alignment: go.Spot.TopLeft})  // with some extra padding around them
          
      //   ),
        
      // );      

      // clicking the button of a default node inserts a new node to the right of the selected node,
      // and adds a link to that new node
      function addNodeAndLink(e, obj) {
        // let getObjData = obj.part.ob;
        // let getJson = myDiagram.model.toJson()
        // _this.save(getJson)
        
      }
      function addData(e, obj) {
        let getObjData = obj.part.ob;
        _this.addData(getObjData)
      }
      function editData(e, obj) {
        let getObjData = obj.part.ob;
        _this.editData(getObjData)
      }
      function delData(e, obj) {
        let getObjData = obj.part.ob;
        _this.delData(getObjData)
      }
      function delData(e, obj) {
        let getObjData = obj.part.ob;
        _this.delData(getObjData)
      }
      function groupData(e, obj) {
        let getObjData = obj.part.ob;
        
        _this.groupData(getObjData)
      }
      function isOnselect(e, obj) {
        // let getObjData = obj.part.isSelected;
        console.log(myDiagram)
        // myDiagram.commandHandler.selectAll();
      }

      // replace the default Link template in the linkTemplateMap
      myDiagram.linkTemplate =
        $(go.Link,  // the whole link panel
          { routing: go.Link.Orthogonal, corner: 5 },
          $(go.Shape, { strokeWidth: 6, stroke: "#555" }),
          new go.Binding("points").makeTwoWay(),
          $(go.Picture,
            //  "/images/icon/hand.png",
              { 
                width: 50, 
                height: 50, 
                imageStretch: go.GraphObject.Uniform 
              },
              new go.Binding("source").makeTwoWay()
               ),
          // { curve: go.Link.Bezier, toShortLength: 0 },
          // new go.Binding("curviness", "curviness"),
          
          // $(go.Shape,  // the link shape
          //   { stroke: "#2F4F4F", strokeWidth: 2.5 }),
          // $(go.Shape,  // the arrowhead
          //   { toArrow: "kite", fill: "#2F4F4F", stroke: null, scale: 2 })
        );

      myDiagram.linkTemplateMap.add("Comment",
        $(go.Link, { selectable: false },
          $(go.Shape, { strokeWidth: 2, stroke: "darkgreen" })));

      // read in the JSON-format data from the "mySavedModel" element
      // _this.load();
      myDiagram.model = go.Model.fromJson({ 
        "class": "go.GraphLinksModel",
        "copiesArrays": true,
        "copiesArrayObjects": true,
        "nodeDataArray": _this.ListDataProps.nodeDataArray,
        "linkDataArray": _this.ListDataProps.linkDataArray
      });
      // _this.layout();
      myDiagram.layoutDiagram(true);
      // myDiagram.addDiagramListener("ObjectSingleClicked",
      // function(e) {
      //   var part = e.subject.part;
      //   if (!(part instanceof go.Link)){
      //     console.log(part.data)
      //     alert(part.data.text)
      //     // showMessage("Clicked on " + part.data.key)
      //   } 
      // });
      this.myDiagramData = myDiagram
    },
    async save() {
      let getJson = this.myDiagramData.model.toJson()
      let getParseJson = JSON.parse(getJson)
      this.$emit('jsonDataReturn',getParseJson)
    },
    async jsonSaveWorkflow() {
      let getJson = this.myDiagramData.model.toJson()
      let getParseJson = JSON.parse(getJson)
      this.$emit('jsonSaveWorkflow',getParseJson)
    },
    load() {
      this.myDiagram.model = go.Model.fromJson(document.getElementById("mySavedModel").value);
    },
    async addData(para_getJson){
      this.$emit('addData',para_getJson)
    },
    async editData(para_getJson){
      this.$emit('editData',para_getJson)
    },
    async delData(para_getJson){
      this.$emit('delData',para_getJson)
    },
    async groupData(para_getJson){
      this.$emit('groupData',para_getJson)
    }
  },
    
}
</script>

<style scoped lang="scss">
.work-flow-card{
 
  margin: 15px;
}
.work-flow-box{
  background-color: white;
  width: 60px;
  height: 60px;
  border: 2px black solid;
  
  border-radius:8px;

}
.box-draw-work-flow{
  border-radius:8px;
  border:none;
  background-color:#C4C4C4;
  overflow: auto
}
.work-flow-doc{
  width: 10px;
  height: 10px;
  border: 1px gray solid;
  border-radius:0px;
  margin: 0px;
}
.hide-banner{
  position: absolute;
  top: 5px;
  left: 20px;
  height: 70px;
  width: 300px;
  z-index: 99;
  background-color: #C4C4C4;
  
}
</style>
