<template>
    <!-- Modal -->
    <div class="modal fade editflow-member" id="editflow-member" role="dialog" tabindex="-1" aria-labelledby="staticBackdropLabel"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content  radius-xl">
                <div class="modal-header justify-content-end">
              
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </button>
                </div>
                <div class="modal-body pt-0 px-3">
                    <div class="editflow-member-modal">
                        
                            <div class="row bg-gray-400">
                                <div class="col-12 p-2">
                                    <h3 class="modal-title fw-500 text-center text-white" id="staticBackdropLabel">Edit Box</h3>
                                </div>
                            </div>
                            <div class="row p-3">
                                <div class="col-12 col-md-5  ">
                                    
                                    <div class="row">
                                        <div class="col-12 box-icon p-3 " :style="`${nodeEditData.fill?`background-color: ${nodeEditData.fill};`:`background-color: #C4C4C4;`}`">
                                            <div v-if="isShowIcon" class="row">
                                                <div v-for="(e) in MainIconsData" :key="e.id" class="col-3 my-1 p-0">
                                                    <img  class="mx-1 pointer" :src="`${e.synology_path}`" @click="nodeEditData.source = `${e.synology_path}`;isShowIcon=false;" width="50"   alt="">
                                                </div>
                                            </div>
                                            <div  v-else class="row">
                                                <div class="col-12 ">
                                                    <img v-if="nodeEditData.source"  :src="nodeEditData.source"  class="w-100" alt=""> 
                                                    <img v-else class="w-100" src="/images/icon/addicon.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 p-0">
                                            <button @click="isShowIcon=true" class="w-100 btn-radius bg-white">Icon</button>
                                        </div>
                                    </div>
                                    
                                </div>
                               
                                <div class="col-12 col-md-7">
                                    <div class="row mb-2">
                                        <div class="col-12 mb-4">
                                            <h1>BOX {{nodeEditData.text}}</h1>
                                        </div>
                                        <div class="col-12">
                                            <label for="text">Template Name</label>
                                            <input disabled v-model="editData.templateName" type="text" class="form-control bg-gray not-allowed"  placeholder="Template Name">
                                        </div>
                                        <div class="col-12">
                                            <label for="text">Box Name</label>
                                            <input v-model="nodeEditData.text" type="text" class="form-control bg-gray"  placeholder="Box Name">
                                        </div>
                                        <div class="col-12">
                                            <label for="Icon Color">Icon Color</label>
                                            <input v-model="nodeEditData.fill" type="color" class="form-control" >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-12 col-md-4">
                                    <label for="name47">Flag Approve</label>
                                    <div class="custom-control custom-switch switch-primary switch-md ">
                                        <input v-model="nodeEditData.flagapprove" type="checkbox" id="switch-flagapprove-edit" checked="" class="custom-control-input" >
                                        <label class="custom-control-label" for="switch-flagapprove-edit"></label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label for="name47">Flag Upload</label>
                                    <div class="custom-control custom-switch switch-primary switch-md ">
                                        <input v-model="nodeEditData.flagupload" type="checkbox" id="switch-flagupload-edit" checked="" class="custom-control-input" >
                                        <label class="custom-control-label" for="switch-flagupload-edit"></label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label for="name47">Flag Send Alert</label>
                                    <div class="custom-control custom-switch switch-primary switch-md ">
                                        <input v-model="nodeEditData.flagsendalert" type="checkbox" id="switch-flagsendalert-edit" checked="" class="custom-control-input" >
                                        <label class="custom-control-label" for="switch-flagsendalert-edit"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                
                                <div class="col-12 col-md-4">
                                    <label for="datepicker">Department</label>
                                    <select v-model="nodeEditData.department" name="department" class="js-example-basic-single js-states form-control" >
                                        <option disabled value="">Please select</option>
                                        <option v-for="(e) in MainDepartmentsData" :key="e.id" :value="e.id"  >
                                            {{e.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label for="text">Count down</label>
                                    <input v-model="nodeEditData.countdown" type="text" class="form-control"  placeholder="Count down">
                                </div>
                                <div class="col-12 col-md-4">
                                    <label for="Unit">Unit</label>
                                    <select v-model="nodeEditData.countdownunit" name="Unit" class="js-example-basic-single js-states form-control" >
                                        <option disabled value="">Please select</option>
                                        <option  value="minute">minute</option>
                                        <option  value="hour">hour</option>
                                        <option  value="day">day</option>
                                        <option  value="month">month</option>
                                        <option  value="year">year</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-md-4">
                                    <label for="text">Normal</label>
                                    <input v-model="nodeEditData.warningoptions.normal" @change="setMinMax($event)" type="number" min="0" max="100" class="form-control"  placeholder="Normal">
                                </div>
                                <div class="col-12 col-md-4">
                                    <label for="text">Warning</label>
                                    <input v-model="nodeEditData.warningoptions.warning" @change="setMinMax($event)" type="number" min="0" max="100" class="form-control"  placeholder="Warning">
                                </div>
                                <div class="col-12 col-md-4">
                                    <label for="text">Emergency</label>
                                    <input v-model="nodeEditData.warningoptions.emergency" @change="setMinMax($event)" type="number" min="0" max="100" class="form-control"  placeholder="Emergency">
                                </div>
                                
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-md-5">
                                    <label for="Document">Document</label>
                                    <select v-model="docBoxData.docId" name="department" class="js-example-basic-single js-states form-control" >
                                        <option disabled value="">Please select</option>
                                        <option v-for="(e) in MainDocData" :key="e.id" :value="e.id"  >
                                            {{e.doc_name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-3">
                                    <label for="text">Count</label>
                                    <input v-model="docBoxData.docCount" type="text" class="form-control"  placeholder="Count">
                                </div>
                                <div class="col-12 col-md-4">
                                    <label for="text">Add</label>
                                    <button @click="addDocBox(nodeEditData)" class="btn btn-secondary" > Add Document</button>
                                </div>
                                
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr class="userDatatable-header"> 
                                                <th>
                                                    <p class="userDatatable-title text-center">Document Name</p>
                                                </th>
                                                <th>
                                                    <p class="userDatatable-title text-center">Document Type</p>
                                                </th>
                                                <th>
                                                    <p class="userDatatable-title text-center">Count</p>
                                                </th>
                                                <th>
                                                    <p class="userDatatable-title text-center">Action</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(e,index) in nodeEditData.actionLists" :key="index" >
                                                <td>
                                                    <div class="userDatatable-content text-center">
                                                        {{e.docName}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="userDatatable-content text-center">
                                                        {{e.actionType}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="userDatatable-content text-center">
                                                        {{e.docCount}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="userDatatable-content">
                                                        <ul class="orderDatatable_actions mb-0 d-flex justify-content-center">
                                                            <li>
                                                                <a @click="DeleteDoc(nodeEditData,e)" class="remove">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="button-group d-flex justify-content-center pt-25">
                                <button @click="EditJobStageTemplates($event)"  class="btn-radius-48 btn-bg-gray text-white px-5 py-3">Edit Box</button>
                            </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import { useI18n } from 'vue-i18n';
export default {
  name: 'ModalEditFlow',
  props: [
    "templateName",
    "selectedNodeEdit",
    "ListDataProps"
  ],
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {

  },
  data: function () {
    return {
        listData:{
    
        },
        editData:{
            templateName:'' ,
            jsnNodeDataArray:[] ,
            jsnLinkDataArray:[]
        },
        nodeEditData:{ 
            key: 1,  
            fill: '',  
            source:'' ,
            text: '',
            department:'',
            flagapprove: true,
            flagupload: false,
            flagsendalert: false,
            warningoptions:{
                normal: 0, 
                warning: 0, 
                emergency: 0
            },
            actionLists:[],
            countdown:'0',
            countdownunit:'day'
        },
        WorkFlowlistData:{
            nodeDataArray:[],
            linkDataArray:[]
        },
        docBoxData:{
            docId:'',
            docName:'',
            actionType:'document',
            docCount:''
        },
        MainDocData:[],
        MainColorsData:[],
        MainIconsData:[],
        MainDepartmentsData:[],
        isShowIcon:false,
    }
  },
  mounted() {
      this.startModal()
      this.GetMainDoc()
    //   this.GetMainColors()
      this.GetMainIcons()
      this.GetMainDepartments()
  },
  methods: {
    async startModal(){
        $('#editflow-member').modal('show');
        let newArray = this.ListDataProps.nodeDataArray.find(e => e.key == this.selectedNodeEdit.key);
        this.nodeEditData = newArray;
        this.editData.templateName = this.templateName
        
        
    },
    async EditJobStageTemplates(){
      try {
          if(this.nodeEditData.department==''){
              throw new Error("กรุณาเลือก Department ก่อนครับ")
          }
        this.editData.jsnNodeDataArray = this.ListDataProps.nodeDataArray.filter(e => e.key != this.selectedNodeEdit.key);
        this.editData.linkDataArray = this.ListDataProps.linkDataArray
        this.editData.jsnNodeDataArray.push(this.nodeEditData)
        let data = {
            templateName:this.editData.templateName ,
            jsnNodeDataArray:JSON.stringify(this.editData.jsnNodeDataArray) ,
            jsnLinkDataArray:JSON.stringify(this.editData.jsnLinkDataArray)
        };
        console.log(data)
        let getAPI = await serviceAPI.call_API('post','workflow/EditJobStageTemplates',data,'auth');
        $('#editflow-member').modal('hide');
        this.$emit('editSuccess')

      } catch (error) {
        $('#editflow-member').modal('hide');
        serviceMain.showErrorAlert(this,error)
      }
    },
    async GetMainDoc(){
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API('get','workflow/DocumentTemplates',data,'auth');
        console.log("GetMainDoc:")
        console.log(getAPI);
        this.MainDocData = getAPI.data.data;
   
        
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async GetMainColors(){
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API('get','workflow/GetMainColors',data,'auth');
        console.log("GetMainColors:")
        console.log(getAPI);
        this.MainColorsData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async GetMainIcons(){
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API('get','workflow/GetMainIcons',data,'auth');
        console.log("GetMainIcons:")
        console.log(getAPI);
        this.MainIconsData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async GetMainDepartments(){
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API('get','workflow/GetMainDepartments',data,'auth');
        console.log("GetMainDepartments:")
        console.log(getAPI);
        this.MainDepartmentsData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async setMinMax(e){
        let getInt = parseInt(e.target.value)
        if(getInt>100){
            e.target.value=100;
        }
        if(getInt<0){
            e.target.value=0;
        }
    },
    async addDocBox(e){
        let getBoxDocDataObj = this.MainDocData.find(e=> e.id==this.docBoxData.docId)
        e.actionLists.push({
            docId:this.docBoxData.docId,
            docName:getBoxDocDataObj.doc_name,
            actionType:this.docBoxData.actionType,
            docCount:this.docBoxData.docCount
        })
    },
    getDocName(val){
        let getObj = this.MainDocData.find(e=> e.id==val)
        return getObj.doc_name
    },
    DeleteDoc(val_Obj,element){
        val_Obj.actionLists = val_Obj.actionLists.filter(e => e.docId !=element.docId)
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },

  },

}
</script>

<!-- Edit "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box-icon{
    max-height: 500px;
    overflow: auto;
}
label{
    color: black !important;
    font-weight:700;
}
</style>
