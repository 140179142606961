<template>
  <div class="button p-4">
    <div class="p-2">
      <div class="row">
        <div class="col">
          <div class="h1 text-white">{{tital}}</div>
          <div class="h6 text-white">{{subtital}}</div>
        </div>
        <div class="col-3 d-flex-justify-content-end">
          <img :src="img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonMain",
  props:['tital',"subtital",'img']
};
</script>

<style scoped>
.button {
  background: linear-gradient(180deg, #1a6c77 49.98%, #22b7a0 100%);
  border-radius: 26px;
}
</style>