<template>
  <div class="row mt-3 p-0">

   
      <div class="col-12">
        <div class="row ">
          <div class="col-12 col-md-3 my-2">
              <select @change="selectedTemplate" v-model="selectedTemplateData" name="Template name" class="js-example-basic-single js-states form-control" >
                  <option disabled value="">Template Name</option>
                  <option v-for="(e) in templateListData" :key="e.job_template_name" :value="e.job_template_name"  >
                      {{e.job_template_name}}
                  </option>
              </select>
          </div>
          <div class="col-12 col-md-3 mb-2">
              <ModalCreateTemplate @addTemplate="getTemplateList" />      
              <ModalAddFlow v-if="isModalAdd" :templateName="selectedTemplateData" :selectedNodeAdd="selectedNodeAdd" :ListDataProps="WorkFlowlistData" @addSuccess="addSuccess" :key="ReloadAddFlow" />  
              <ModalEditFlow v-if="isModalEdit" :templateName="selectedTemplateData" :selectedNodeEdit="selectedNodeEdit" :ListDataProps="WorkFlowlistData" @editSuccess="editSuccess" :key="ReloadEditFlow" />
          </div>
          <div class="col-12 col-md-3 mb-2">
            <Button1 @click="DeleteJobStageTemplates" tital="Delete Template" />
              <!-- <a @click="DeleteJobStageTemplates" class="btn w-100 px-15 text-white btn-danger">
                Deleate Template
              </a> -->
          </div>
          <div class="col-12 col-md-3 mb-2">
            <Button1 @click="saveWorkflow" tital="Save Template" />
              <!-- <a @click="saveWorkflow" class="btn w-100 px-15 btn-primary text-white">
                Save Workflow
              </a> -->
          </div>
        </div>
      </div>

      
      <div class="col-12 ">
        <WorkFlowList v-if="WorkFlowlistData.nodeDataArray !=''" ref="WorkFlowListRef" :ListDataProps="WorkFlowlistData" :key="ReloadWorkFlowList" @addData="addData" @editData="editData" @delData="delData" @groupData="groupData" @jsonDataReturn="jsonDataReturn" @jsonSaveWorkflow="jsonSaveWorkflow" />    
      </div>
      <div class="col-12">
        <ModalCreateGroupFlow v-if="groupArray!=''" :templateName="selectedTemplateData" :groupArrayProps="groupArray" @AddGroupSuccess="AddGroupSuccess" :key="ReloadCreateGroupFlow" />  
      </div>
      
      
  </div>
</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import WorkFlowList from '@/components/workflow/WorkFlowList.vue';
import ModalCreateTemplate from '@/components/workflow/ModalCreateTemplate.vue';
import ModalAddFlow from '@/components/workflow/ModalAddFlow.vue';
import ModalEditFlow from '@/components/workflow/ModalEditFlow.vue';
import ModalCreateGroupFlow from '@/components/workflow/ModalCreateGroupFlow.vue';
import Button1 from "@/components/Common/Button1.vue";
import { useI18n } from 'vue-i18n';
export default {
  name: 'WorkFlow',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {
    WorkFlowList,
    ModalCreateTemplate,
    ModalAddFlow,
    ModalEditFlow,
    ModalCreateGroupFlow,
    Button1
  },
  data: function () {
    return {
        WorkFlowlistData:{
          nodeDataArray: [],
          linkDataArray: [],
          // groupDataArray:[]
        },
        ModifyWorkFlowlistData:{
          nodeDataArray: [],
          linkDataArray: []
        },
        templateListData:[],
        selectedTemplateData:'',
        selectedNodeAdd:'',
        selectedNodeEdit:'',
        ReloadAddFlow:null,
        ReloadEditFlow:null,
        ReloadWorkFlowList:null,
        ReloadCreateGroupFlow:null,
        isModalAdd:false,
        isModalEdit:false,
        groupArray:[]
    }
  },
  mounted() {
    this.getTemplateList()
  },
  methods: {

    async getTemplateList(){
      try {
        let data = {}
        let getAPI = await serviceAPI.call_API('get','workflow/GetListJobStageTemplates',data,'auth');
        this.templateListData = getAPI.data.data
        
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async selectedTemplate(){
      try {
        let data = { templateName:this.selectedTemplateData }
        let getAPI = await serviceAPI.call_API('get','workflow/GetJobStateTemplates',data,'auth');
        let getData = getAPI.data.data[0]
        console.log(getData)
        let getParseJsonNodeDataArray = JSON.parse(getData.NODEDATAARRAY.replace(/, "group": null/g, ''))
        let getParseJsonGroupDataArray = JSON.parse(getData.NODEDATAGROUP)
        
        let getParseJsonLinkDataArray = JSON.parse(getData.LINKDATAARRAY)
        console.log(getParseJsonNodeDataArray)
        console.log(getParseJsonLinkDataArray)
        let getMapParseWarningOptions = getParseJsonNodeDataArray.map(itm => ({
          ...itm,
          warningoptions:JSON.parse(itm.warningoptions),
          actionLists:JSON.parse(itm.actionLists),
          actionBars:JSON.parse(itm.actionBars)
        }))
        if(getParseJsonGroupDataArray){
          for(let e of getParseJsonGroupDataArray){
            getMapParseWarningOptions.push(e)
          }
        }
        
        this.WorkFlowlistData.nodeDataArray = getMapParseWarningOptions
        this.WorkFlowlistData.linkDataArray = getParseJsonLinkDataArray
        // this.WorkFlowlistData.groupDataArray = getParseJsonGroupDataArray
        this.ReloadWorkFlowList = Date.now();
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async jsonDataReturn(val){
      try {
        console.log("jsonDataReturn")
        console.log(val)
        // this.ModifyWorkFlowlistData.nodeDataArray = val.nodeDataArray
        // this.ModifyWorkFlowlistData.linkDataArray = val.linkDataArray
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async addData(val){
      try {
        console.log("addData")
        console.log(val)
        this.selectedNodeAdd = val;
        this.ReloadAddFlow= Date.now();
        this.isModalAdd=true;
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async editData(val){
      try {
        console.log("editData")
        console.log(val)
        this.selectedNodeEdit = val;
        this.ReloadEditFlow= Date.now();
        this.isModalEdit=true;
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async addSuccess(){
      try{
        this.selectedTemplate()
      }catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async editSuccess(){
      try{
        this.selectedTemplate()
      }catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async delData(val){
      try {
        console.log("delData")
        console.log(val)
        this.DeleteJobStageNodeTemplates(val)
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async groupData(val){
      try {
        console.log("groupData")
        console.log(val)
        for(let e of this.groupArray){
          if(e.key == val.key){
            throw new Error("Box Duplicate")
          }
        }
        this.groupArray.push(val)
        this.ReloadCreateGroupFlow = Date.now();
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async saveWorkflow(){
      try{
        if (!this.canEditTemplate) {
          throw new Error('ไม่สามารถเข้าถึงสิทธิ์การแก้ไข Template ได้')
        }
        this.$refs.WorkFlowListRef.jsonSaveWorkflow()
      }catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async jsonSaveWorkflow(val){
      try {
        let data = {
            templateName:this.selectedTemplateData ,
            jsnNodeDataArray:JSON.stringify(val.nodeDataArray) ,
            jsnLinkDataArray:JSON.stringify(val.linkDataArray)
        };
        console.log(data)
        let getAPI = await serviceAPI.call_API('post','workflow/EditJobStageTemplates',data,'auth');
        this.selectedTemplate()

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
     
    },
    async DeleteJobStageNodeTemplates(val){
      try {
 
        let r = confirm("คุณต้องการลบ Box นี้ใช่ไหม");
        if (r == false) {
          throw new Error('ยกเลิกการลบ')
        } 
        let data = {
            templateName:this.selectedTemplateData , 
            key:val.key
        };
        let getAPI = await serviceAPI.call_API('post','workflow/DeleteJobStageNodeTemplates',data,'auth');
        console.log("DeleteJobStageNodeTemplates:")
        console.log(getAPI); 
        this.selectedTemplate()
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async DeleteJobStageTemplates(){
      try {
        if (!this.canDeleteTemplate) {
          throw new Error('ไม่สามารถเข้าถึงสิทธิ์การลบ Template ได้')
        }
        if(this.selectedTemplateData==''){
          throw new Error('กรุณาเลือก Template ก่อนครับ')
        }
        let r = confirm("คุณต้องการลบ Template ใช่ไหม");
        if (r == false) {
          throw new Error('ยกเลิกการลบ')
        } 
        let data = {
            templateName:this.selectedTemplateData
        };
        let getAPI = await serviceAPI.call_API('post','workflow/DeleteJobStageTemplates',data,'auth');
        console.log("DeleteJobStageTemplates:")
        console.log(getAPI); 
        this.selectedNodeAdd=''
        this.WorkFlowlistData={
          nodeDataArray: [],
          linkDataArray: []
        }
        this.getTemplateList();
        
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async AddGroupSuccess(){
      this.groupArray=[]
      this.selectedTemplate()
    }

  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    user: function () {
      return this.$store.getters["auth/getUser"];
    },
    canEditTemplate: function () {
      return this.user.is_admin || this.user.permissions.template_edit;
    },
    canDeleteTemplate: function () {
      return this.user.is_admin || this.user.permissions.template_delete;
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.btn-Deleate{
  background-color:red;
  color: white;
}
</style>
