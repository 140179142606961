<template>
  <div class="mani-bg pb-5">
    <div class="row m-0">
      <div class="col-12 d-flex justify-content-end">
        <svg
          class="mt-3 mr-3"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.707 7.70703L11.414 10L13.707 12.293C14.098 12.684 14.098 13.316 13.707 13.707C13.512 13.902 13.256 14 13 14C12.744 14 12.488 13.902 12.293 13.707L10 11.4139L7.70697 13.707C7.51197 13.902 7.256 14 7 14C6.744 14 6.48803 13.902 6.29303 13.707C5.90203 13.316 5.90203 12.684 6.29303 12.293L8.586 10L6.29303 7.70703C5.90203 7.31603 5.90203 6.68397 6.29303 6.29297C6.68403 5.90197 7.31597 5.90197 7.70697 6.29297L10 8.58606L12.293 6.29297C12.684 5.90197 13.316 5.90197 13.707 6.29297C14.098 6.68397 14.098 7.31603 13.707 7.70703ZM20 7V13C20 16.859 16.86 20 13 20H7C3.14 20 0 16.859 0 13V7C0 3.141 3.14 0 7 0H13C16.86 0 20 3.141 20 7ZM18 7C18 4.243 15.757 2 13 2H7C4.243 2 2 4.243 2 7V13C2 15.757 4.243 18 7 18H13C15.757 18 18 15.757 18 13V7Z"
            fill="#41416E"
          />
        </svg>
      </div>
      <div class="col-12 imgsize mt-5">
        <div class="d-flex justify-content-center">
          <img src="/images/logo_eco.png" alt="" />
        </div>
      </div>
      <div class="col-12 mt-1">
        <div class="d-flex justify-content-center">
          <img class="bglogosize" src="/images/bglogo.png" alt="" />
        </div>
      </div>
      <div class="col-12 mt-5">
        <ButtonMain
          tital="Contract"
          subtital="เซ็นสัญญา แจ้งยอดชำระ"
          img="/images/clock.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonMain from "@/components/App/Common/ButtonMain.vue";
export default {
  name: "AppCustomer",
  components: { ButtonMain },
  data() {
    return {};
  },
};
</script>

<style scoped>
.mani-bg {
  height: 100%;
  min-height: 100vh;
  max-height: 180vh;
  background: conic-gradient(
    from 181.23deg at 50% 36.21%,
    rgba(44, 153, 255, 0.43) -17.68deg,
    #ffffff 197.95deg,
    rgba(44, 153, 255, 0.43) 342.32deg,
    #ffffff 557.95deg
  );
}
.imgsize {
  width: 118px;
  height: 118px;
}
.bglogosize {
  /* width: 250px; */
  /* height: 250px; */
  width: 100%;
  min-width: 100px;
  max-width: 300px;
}
.button {
  background: linear-gradient(180deg, #1a6c77 49.98%, #22b7a0 100%);
  border-radius: 26px;
}
</style>